import loadable from '@loadable/component';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox,
  OwnUpHeader
} from '@rategravity/own-up-component-library';
import React from 'react';
import { HorizontalLine } from '../components/horizontal-line';
import { Layout } from '../components/layout';
import {
  Highlight,
  ResponsivePrimaryText,
  ResponsiveSectionHeader
} from '../components/typography';
import { faqWithAnswers } from '../data/content/faq';

const DotStrip = loadable(() => import('../components/dot-strip'), {
  resolveComponent: (components) => components.DotStrip
});

const FAQHeaderStyle = createOwnUpStyle({
  marginBottom: 75,
  display: 'flex',
  justifyContent: 'center',
  variants: {
    mediumAndDown: {
      marginBottom: 50
    }
  }
});

const QuestionsHeaderStyle = createOwnUpStyle({
  variants: {
    mediumAndDown: {
      textAlign: 'center'
    }
  }
});

const QuestionsStyle = createOwnUpStyle({
  margin: '0 32px'
});

const FAQHeader = createOwnUpComponent(ResponsiveSectionHeader, FAQHeaderStyle);
const QuestionsHeader = createOwnUpComponent(OwnUpHeader, QuestionsHeaderStyle);
const QuestionsWrapper = createOwnUpComponent(OwnUpBox, QuestionsStyle);

export const FAQ = () => (
  <Layout>
    <QuestionsWrapper>
      <FAQHeader variant="title">
        <Highlight>Frequently Asked Questions</Highlight>
      </FAQHeader>
      {faqWithAnswers.map(({ question, answer }, i) => (
        <React.Fragment key={i}>
          <QuestionsHeader>{question}</QuestionsHeader>
          <ResponsivePrimaryText>{answer}</ResponsivePrimaryText>
          <HorizontalLine variant="faqLong" />
        </React.Fragment>
      ))}
    </QuestionsWrapper>
    <DotStrip />
  </Layout>
);

export default FAQ;
